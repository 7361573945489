import * as $ from "jquery";

/**
 *
 */
class LoadMore{
    constructor() {
        this.loadMoreBtn = $('#rw_show_more');
        this.init();
    }
    init(){
        if( ! this.loadMoreBtn.length ){
            return;
        }

        this.loadMoreBtn.on('click',(e)=> this.handleLoadMorePosts(e) );
    }

    handleLoadMorePosts(e){
        e.preventDefault();
        e.stopPropagation();

        const showMore = $('#rw_show_more');
        const nonce = this.loadMoreBtn.data('nonce');
        const loader = $('#loader');
        const maxNumPages = this.loadMoreBtn.data('max-pages');
        const queryVars = this.loadMoreBtn.data('query-vars');
        const currentPage = this.loadMoreBtn.data('current-page');
        const postType = this.loadMoreBtn.data('post-type');
        const taxQuery = this.loadMoreBtn.data('tax-query');
        const data = {
            'action': 'loadmore',
            'nonce': nonce,
            'query_vars': queryVars,
            'page' : currentPage,
            'post_type':postType,
            'tax_query':taxQuery
        };
        console.log(data);
        showMore.hide();
        loader.show();
        $.ajax({
            url:localizedScript.url,
            data:data,
            type:'POST',
            success:function(data){
                if( data ) {
                    $('.load-more-wrapper').before(data);
                    showMore.data('current-page',currentPage + 1);
                    showMore.show();
                    loader.hide();
                    if (currentPage === maxNumPages){
                        showMore.parent().hide();
                        showMore.remove();
                        loader.hide();
                    }
                } else {
                    showMore.parent().hide();
                    showMore.remove();
                    loader.hide();
                }
            }
        });
    }
}

new LoadMore();