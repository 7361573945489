/**
 * ********************************************************
 * ---------------------Scroll Effects---------------------
 * ========================================================
 * */
import * as $ from "jquery";


var wwaItem = document.getElementsByClassName('services-item');
function wwaEffect(k){
    var z = wwaItem[k];
    setTimeout(function() {$(z).css({'opacity': 1})}, 1 + k * 100);
}
var gallItem = document.getElementsByClassName('gallery-item');
function gallEffect(m){
    var g = gallItem[m];
    setTimeout(function() {$(g).css({'transform':'scale(1)'})}, 1 + m * 500);
}

function isScrolledIntoView(elem){
    if(elem.length !== 0){
        var $elem = $(elem);
        var $window = $(window);
        var docViewTop = $window.scrollTop();
        var docViewBottom = docViewTop + $window.height();
        var elemTop = $elem.offset().top;
        var elemBottom = elemTop + $elem.height();
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
    }
    else{
        return false;
    }
}


(function($) {
    var clientHeight = $(window).height();
    var items = [];
    $(document).scroll(function() {
        if(isScrolledIntoView($('.services-wrapper'))){
            for(var k = 0; k < wwaItem.length; k++){
                wwaEffect(k);
            }
        }
        if(isScrolledIntoView($('.gallery'))){
            for(var m = 0; m < gallItem.length; m++){
                gallEffect(m);
            }
        }

        for(var i = 0; i < items.length; i++) {
            if(items[i].ele.offset().top - $(document).scrollTop() - clientHeight < 350) {
                if(typeof items[i].arg === 'string') {
                    items[i].ele.addClass(items[i].arg);
                } else if(items[i].arg instanceof Function) {
                    items[i].arg.call();
                }
            }
        }
    });

    // Custom JQuery extending
    $.fn.extend({
        listenedByScroll: function(arg) {
            $(document).scroll();
            if(!arg) return this;
            items.push({ele: $(this), arg: arg});
            return this;
        }
    });
})($);