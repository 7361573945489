import * as $ from 'jquery';
import 'owl.carousel';

/**
 * *************************************************
 * ============== Owl Carousel sliders =============
 * *************************************************
 */
// Top slider
if ( $('.home').length !== 0 ){
    $('#top_slider').owlCarousel({
        loop:true,
        dots: false,
        nav: false,
        lazyLoad: true,
        animateOut: 'fadeOut',
        smartSpeed:1000,
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true,
        items:1
    });
    // Video slider
    $("#owl_videos").owlCarousel({
        items : 3,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            769:{
                items:1
            },
            1200:{
                items:3
            }
        },
        loop: true,
        dots: false,
        pagination: false,
        lazyLoad: true,
        nav: true,
        navElement: 'button type="button" aria-label="navigation" role="presentation"',
        navText: ["<i aria-label='Previous' class='fa fa-angle-double-left'></i>","<i aria-label='Next' class='fa fa-angle-double-right'></i>"],
        animateOut:'fadeOut',
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true
    });
}
// Our team slider
if($('#owl-team').length !== 0 ){
    $("#owl-team").owlCarousel({
        items : 4,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:3
            },
            1000:{
                items:4
            }
        },
        loop: true,
        lazyLoad: true,
        nav: true,
        dots: false,
        navElement: 'button type="button" aria-label="navigation" role="presentation"',
        navText: ["<i aria-label='Previous' class='fa fa-angle-double-left'></i>","<i aria-label='Next' class='fa fa-angle-double-right'></i>"],
        animateOut:'fadeOut',
        autoplay:true,
        autoplayTimeout:5000,
        autoplayHoverPause:true
    });
}