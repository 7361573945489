export const bootTOCComponent = () => {

	(function( $ ) {
		'use strict';

		var toc = function (options) {

			return this.each(function () {
				var root = $(this),
					data = root.data(),
					thisOptions,
					stack = [root], // The upside-down stack keeps track of list elements
					listTag = this.tagName,
					currentLevel = 0,
					headingSelectors;

				// Defaults: plugin parameters override data attributes, which override our defaults
				thisOptions = $.extend(
					{content: "body", headings: "h1,h2,h3"},
					{content: data.toc || undefined, headings: data.tocHeadings || undefined},
					options
				);
				headingSelectors = thisOptions.headings.split(",");

				// Set up some automatic IDs if we do not already have them
				$(thisOptions.content).find(thisOptions.headings).attr("id", function (index, attr) {
					var generateUniqueId = function (text) {

						if (text.length === 0) {
							text = "?";
						}

						var baseId = text.replace(/[^a-zA-Z0-9]/g, "_"), suffix = "", count = 1;

						while (document.getElementById(baseId + suffix) !== null) {
							suffix = "_" + count++;
						}

						return baseId + suffix;
					};

					return attr || generateUniqueId($(this).text());
				}).each(function () {
					// What level is the current heading?
					var elem = $(this), level = $.map(headingSelectors, function (selector, index) {
						return elem.is(selector) ? index : undefined;
					})[0];

					if (level > currentLevel) {
						// If the heading is at a deeper level than where we are, start a new nested
						// list, but only if we already have some list items in the parent. If we do
						// not, that means that we're skipping levels, so we can just add new list items
						// at the current level.
						// In the upside-down stack, unshift = push, and stack[0] = the top.
						var parentItem = stack[0].children("li:last")[0];
						if (parentItem) {
							stack.unshift($("<" + listTag + "/>").appendTo(parentItem));
						}
					} else {
						// Truncate the stack to the current level by chopping off the 'top' of the
						// stack. We also need to preserve at least one element in the stack - that is
						// the containing element.
						stack.splice(0, Math.min(currentLevel - level, Math.max(stack.length - 1, 0)));
					}

					// Add the list item
					$("<li/>").appendTo(stack[0]).append(
						$("<a/>").text(elem.text()).attr("href", "#" + elem.attr("id"))
					);

					currentLevel = level;
				});
			});
		}
		//var	old = $.fn.toc;

		//$.fn.toc = toc;

		/*$.fn.toc.noConflict = function () {
			$.fn.toc = old;
			return this;
		};*/

		$(function () {
			//toc.call($("[data-toc]"));
		});


		/*if( $('div.js-toc_table_default')[0]){
			$(".js-toc_table_default ul").toc({content: ".the-content", headings: "h2,h3,h4,h5,h6"});
			$('.js-toc_table').hide();
		}else{
			$(".js-toc_table_list_heading ul").toc({content: ".the-content", headings: "h2,h3,h4,h5,h6"});
		}*/

		$('.js-toc_table_list_heading ul li a').on("click", function (event) {
			event.preventDefault();
			var id = $(this).attr("href");
			var top = $(id).offset().top - 80;
			$('html, body').animate({scrollTop: top}, 1000);
		});

		$('.js-toc_table_title').on('click',function () {
			$(this).toggleClass('active');
			$(this).next().slideToggle(500);
		});

	})( jQuery );

}




