import * as $ from "jquery";
import {$body} from "./GlobalVars";


/**
 *
 */
class ShowComments{
    constructor() {
        this.loadComments = $('.load-comments-js');
        this.init();
    }
    init(){
        if( ! this.loadComments.length ){
            return;
        }
        this.loadComments.on('click',(e)=> this.handleLoadComments(e) );
        $body.on('click','.show-comments-js',()=> this.handleShowComments() );
        this.handleCommentsRating();
    }

    /**
     * Displaying comment by click
     */
    handleShowComments(){
        $('#comments').slideToggle();
        $body.find('.show-comments-js').find('i').toggleClass("fa-caret-up fa-caret-down");
        const $selector = $body.find('.show-comments-text-js');
        const stateOne = 'Сховати коментарі';
        const stateTwo = 'Показати коментарі';
        if( $($selector).text() !== stateTwo ){
            $($selector).text(stateTwo);
        }else{
            $($selector).text(stateOne);
        }


    }

    /**
     * Comments rating
     */
    handleCommentsRating(){
        $body.on('change',"input[name='rating']",function(){
            const rating = parseInt($(this).val());
            $('.heart-keeper').each(function(i){
                if( i+1 <= rating ){
                    $(this).find('i').removeClass('fa-heart-o').addClass('fa-heart');
                }else{
                    $(this).find('i').removeClass('fa-heart').addClass('fa-heart-o');
                }
            });
        });
    }


    handleLoadComments(e){
        // Loads comments by Ajax
            e.preventDefault();
            const currentId = this.loadComments.data('current-id');
            const nonce = this.loadComments.data('nonce');
            // var dataModeration = $(this).data('moderation');
            $.ajax({
                method: 'POST',
                url: localizedScript.url,
                data: {
                    action: 'rw_load_comments',
                    postID: currentId,
                    nonce: nonce
                    //dataModeration: dataModeration
                },

                success: function( data ) {
                    console.log(data);
                    $('#comments_wrapper').html( data );
                    $('#load_comments').hide();
                }
            });

    }
}

new ShowComments();