import * as $ from "jquery";
import {$body, $siteHtml} from "./GlobalVars";

/**
 * ------------------------------------------------- Calculator solutions ------------------------------------------
 */
function getFile(filePath) {
    return filePath.substr(filePath.lastIndexOf('\\') + 1).split('.')[1];
}


if( $('.calculator-wrapper').length ) {
    $('.calculator-navigation-item-js').on('click',function(){
        var dataNav = $(this).data('nav');
        $('.calculator-navigation-item-js').removeClass('active');
        $(this).addClass('active');
        $('.calculator-tab-content-item-js').removeClass('active');
        $('.calculator-form').removeClass('calculator-form-active');
        $('[data-tab-content='+dataNav+']').addClass('active').find('form').addClass('calculator-form-active');
    });
}

$body.on('change','.calculator-form-active .item-quantity-js',function(){
    $('.calculator-form-active #range_number').val($(this).val());
});
$body.on('change','.calculator-form-active .range-number-js',function(){
    $('.calculator-form-active .item-quantity-js').val($(this).val());
});
function get_adapted_sizes(w= 1,h=1 ){
    var diff = 0;
    if( w === h ){
        w = 220;
        h = 220;
    }
    if( w > h ){
        diff = (h * 100) / w;
        w = 220;
        h = (diff * 220) / 100;
    }
    if( w < h ){
        diff = (w * 100) / h;
        h = 220;
        w = (diff * 220) / 100;
    }
    return [w, h];
}
$body.on('change','.calculator-form-active .item-width',function(){
    var width = $(this).val();
    var height = 0;
    if( $body.find('.calculator-form-active').find('.item-height').val() ){
        height = $body.find('.calculator-form-active').find('.item-height').val();
    }
    var adaptedSizes = get_adapted_sizes(parseInt(width),parseInt(height));
    var html = '<span><b>'+width+' X ' + height +' см.</b><br><small> ( ' + (width*height)/10000 + ' м<sup>2</sup> )</small></span>'
    $body.find('.calculator-form-active .calculator-rectangle').css({'width':adaptedSizes[0] +'px','height':adaptedSizes[1]+'px'}).html(html);
});

$body.on('change','.calculator-form-active .item-height',function(){
    var height = $(this).val();
    var width = 0;
    if( $body.find('.calculator-form-active').find('.item-width').val() ){
        width = $body.find('.calculator-form-active').find('.item-width').val();
    }
    var adaptedSizes = get_adapted_sizes(parseInt(width),parseInt(height));
    var html = '<span><b>'+width+' X ' + height +' см.</b><br><small> ( ' + (width*height)/10000 + ' м<sup>2</sup> )</small></span>'
    $body.find('.calculator-form-active .calculator-rectangle').css({'width':adaptedSizes[0] +'px','height':adaptedSizes[1]+'px'}).html(html);
});

/**
 * @var vinil_tape_material_select
 */
$body.on('change','.calculator-form-active .select-material',function(){
    var actualSelect = $(this).val();
    var $productTypeData =  $('.dynamic-calculator-form-data .product-type-data-js');
    switch (actualSelect){
        case 'vinil_type':
            $productTypeData.addClass('hidden');
            $body.find('.calculator-form-active .vinil-type-data').removeClass('hidden');
            break;
        case 'one_way_vision':
            $productTypeData.addClass('hidden');
            $body.find('.calculator-form-active .one-way-vision-type-data').removeClass('hidden');
            break;
        case 'baner':
            $productTypeData.addClass('hidden');
            $body.find('.calculator-form-active .baner-data').removeClass('hidden');
            break;
        case 'paper':
            $productTypeData.addClass('hidden');
            $body.find('.calculator-form-active .paper-data').removeClass('hidden');
            break;
        case 'canvas':
            $productTypeData.addClass('hidden');
            $body.find('.calculator-form-active .canvas-data').removeClass('hidden');
            break;
        default:
            break;
    }
});

$body.on('submit','.calculator-form-active',function(e){
    e.preventDefault();
    var $that = $(this);
    var $prePrice = $that.find(".pre-price");
    var $discount = $that.find(".discount");
    var $final_price = $that.find(".final-price");

    var dataCount = '';
    var data = new FormData($(this).get(0));
    for (var [key, value] of data.entries()) {
        //console.log(key, value);
        if( key !== 'range_number' ){
            dataCount = dataCount + key + '-' + value + ';';
        }
    }
    console.log(data);
    $.ajax({
        type: 'POST',
        url: localizedScript.url,
        data:{
            action: 'calculator_count',
            data_count: dataCount,
        },
        success: function (res) {
            console.log(res);
            console.log(res.data.final_sum);
            $prePrice.text(res.data.final_sum);
            $final_price.text(res.data.final_sum);
        },
        error: function(jqxhr, status, exception) {
            console.log('Exception:', exception);
            console.log('Status:', status);
            console.log('jqxhr:', jqxhr);
        }
    });



    $('#calculator_order_text').val(dataCount);

});

$('#calculator_order_form').on('submit',function(e){
    e.preventDefault();
    var $that = $(this);
    var errorBlock = $('.calculator-error-block');
    $(errorBlock).html('');
    var orderData = new FormData(this);
    var designFile = $that.find('#calculator_order_client_design_file').val();
    var extension = getFile($that.find('#calculator_order_client_design_file').val());
    var designFileValidation = true;

    if(designFile){
        if($('#calculator_order_client_design_file')[0].files[0].size > 5000000){
            $(errorBlock).append('<p>Завантаження до 5mb.</p>');
            $that.find('#calculator_order_client_design_file').addClass('error-border');
            designFileValidation = false;
        }else{
            $(errorBlock).html('');
            $that.find('#calculator_order_client_design_file').removeClass('error-border');
            designFileValidation = true;
        }
    }
    if(designFile){
        if($('#calculator_order_client_design_file')[0].files[0].size <= 5000000 && extension === 'png' || extension === 'jpg' || extension === 'jpeg'){
            $(errorBlock).html('');
            $that.find('#calculator_order_client_design_file').removeClass('error-border');
            designFileValidation = true;
        }else{
            $(errorBlock).append('<p>Завантажте файл з розширенням .png, jpg, jpeg</p>');
            $that.find('#calculator_order_client_design_file').addClass('error-border');
            designFileValidation = false;
        }
    }

    var customerEmail = $("#calculator_order_client_email").val();
    var mail_pattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
    var check_mail = mail_pattern.test(customerEmail);

    var userName = $("#calculator_order_client_name").val();
    var userPhone = $("#calculator_order_client_phone").val();
    var phone_pattern = /^\+?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{0,6})$/;
    var check_phone = phone_pattern.test(userPhone);

    if( userPhone !== '' && check_phone !== true ){
        $that.find("input[name='calculator_order_client_phone']").addClass('error-border');
    }
    else if (userPhone === ''){
        $that.find("input[name='calculator_order_client_phone']").addClass('error-border');
    }
    else{
        $that.find("input[name='calculator_order_client_phone']").removeClass('error-border');
    }
    if( customerEmail === '' ){
        $that.find("input[name='calculator_order_client_email']").addClass('error-border');
    }
    else if( check_mail !== true ){
        $that.find("input[name='calculator_order_client_email']").addClass('error-border');
    }else{
        $that.find("input[name='calculator_order_client_email']").removeClass('error-border');
    }
    if( userName === '' ){
        $that.find("input[name='calculator_order_client_name']").addClass('error-border');
    }else{
        $that.find("input[name='calculator_order_client_name']").removeClass('error-border');
    }

    if( userName && check_mail && check_phone && designFileValidation ){
        $(errorBlock).html('');
        orderData.append('action','calculator_order_form');
        orderData.append('captcha',grecaptcha.getResponse(0));
        orderData.append('calculator_order_client_design_file',$('#calculator_order_client_design_file')[0].files[0] );
        $.ajax({
            type: "POST",
            enctype: 'multipart/form-data',
            url: localizedScript.url,
            data: orderData,
            processData: false,
            contentType: false,
            cache: false,
            timeout: 800000,
            success: function (data) {
                console.log("SUCCESS : ", data);
                var parsedData = JSON.parse(data);
                if( parsedData.status === 'success'){
                    $(errorBlock).html('');
                    $that[0].reset();
                    grecaptcha.reset(0);
                    var orderId = parsedData.order_id;
                    $('#order_id').text(orderId);
                    $('.calculator-thank-popup-wrapper').fadeIn();
                    setTimeout(function(){
                        $('.calculator-thank-popup-wrapper').fadeOut();
                        $body.removeClass('shadow');
                        $siteHtml.removeClass('shadow');
                    }, 7000);
                }else if (parsedData.status === 'failed'){
                    $(errorBlock).append('<p>Гугл рекаптча не пройдена!</p>');
                }else if(parsedData.status === 'recaptcha_keys_missing'){
                    $(errorBlock).append('<p>Упс... Щось пішло не так</p>');
                }else{
                    $(errorBlock).append('<p>Не коректний запит</p>');
                }

            },
            error: function (e) {
                console.log("ERROR : ", e.responseText);
            }
        });
    }



});
