import * as $ from 'jquery';
import bootReadCookie from "./ReadCookies";
import bootSetCookie from "./SetCookies";

async function hasClicked(data){
   const userClicked = await bootReadCookie('user_clicked_'+ data);
    if( userClicked ){
        return true;
    }
    return false;
}



// Likes clicks
$('.js-like').on('click',async function(){
    var currentItem = $('.gallery-item-js.active');
    var currentPostId = parseInt(currentItem.data('post-id'));
    var activeSlide = parseInt(currentItem.data('order'));
    var srcUrl = currentItem.data('src-url');
    var currentLikes = parseInt(currentItem.data('likes'));

    if( ! await hasClicked(srcUrl) ){
        $.ajax({
            type: 'POST',
            url: likesScript.url,
            data:{
                action: likesScript.action,
                nonce: likesScript._wp_nonce,
                post_id: currentPostId,
                active_slide: activeSlide,
                current_likes: currentLikes
            },
            success: function (result) {
                var res = JSON.parse(result);
                if( res.status === 'success' ){
                    $('.total-virtual-likes').text(currentLikes+1);
                    currentItem.find('.likes-total').text(currentLikes+1);
                    bootSetCookie('user_clicked_'+ srcUrl,'clicked',5);
                }else{
                    console.log(res.status);
                }
            }
        });
    }else{
        console.log('You have already clicked this photo');
    }

});