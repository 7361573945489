import * as $ from "jquery";

/**
 *  ============= Gallery Slider ==================
 * ************************************************
 */
var lastX,
    run,
    allItems = $('.g-gallery-item').length - 1;
var gallerySlide = $('#gallery_slide');
// Exit from gallery
$('.g-exit-js').on('click',function(){
    $('#g_play').removeClass('disabled active').addClass('enabled');
    $('#g_stop').removeClass('disabled active');
    clearInterval(run);
    $('#gallery_popup_slider').fadeOut();
});

// Exit from gallery by click outside
$('#gallery_popup_slider').on('click',function(event){
    if (!$(event.target).closest('.gallery-navigation').length && !$(event.target).closest("#gallery_slide img").length) {
        $('#g_play').removeClass('disabled active').addClass('enabled');
        $('#g_stop').removeClass('disabled active');
        clearInterval(run);
        $("#gallery_popup_slider").fadeOut();
    }
});

// Prev and Next slide by touch move in mobiles
gallerySlide.bind('touchstart','img', function(e) {
    lastX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
});
gallerySlide.bind('touchmove','img', function(e) {
    var currentX = e.originalEvent.touches ? e.originalEvent.touches[0].pageX : e.pageX;
    if (Math.abs(currentX-lastX) < 30) { return; }
    if (currentX > lastX) {
        prevSlideAction();
    } else {
        nextSlideAction();
    }
});


// Previous slide event handler
$('#g_prev').on('click',prevSlideAction);

function prevSlideAction(){
    var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
    var prevSlide = getPrevSlide(activeSlide);
    $('.gallery-item-js').removeClass('active');
    var prevObj =  $('.g-gallery-item').eq(prevSlide);
    $(prevObj).addClass('active');
    generateSlideMarkup(prevObj);
}

function getPrevSlide(activeSlide){
    if(activeSlide > 0){
        return activeSlide-1;
    }
    if(activeSlide === 0){
        return allItems;
    }
}

// Next slide
$('#g_next').on('click',nextSlideAction);

function nextSlideAction(){
    var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
    var nextSlide = getNextSlide(activeSlide);
    $('.gallery-item-js').removeClass('active');
    var nextObj =  $('.g-gallery-item').eq(nextSlide);
    $(nextObj).addClass('active');
    generateSlideMarkup(nextObj);
}

function getNextSlide(activeSlide){
    if(activeSlide < allItems){
        return activeSlide+1;
    }
    if(activeSlide === allItems){
        return 0;
    }
}

$('.g-control').on('click',function(){
    $('.g-control').removeClass('active');
    $(this).addClass('active');
});

$('#g_play').on('click',function(){
    if( $(this).hasClass('enabled') ){
        run = setInterval(function(){ gallerySliderRun()},3000);
        $(this).removeClass('enabled').addClass('disabled');
    }
});

$('#g_stop').on('click',function(){
    $('#g_play').removeClass('disabled').addClass('enabled');
    clearInterval(run);
});

function gallerySliderRun(){
    if( !$('.gallery-items').find('#gallery-navigation').hasClass('stop') ){
        var activeSlide = parseInt($('.gallery-item-js.active').data('order'));
        var nextSlide = getNextSlide(activeSlide);
        $('.gallery-item-js').removeClass('active');
        var nextObj =  $('.g-gallery-item').eq(nextSlide);
        $(nextObj).addClass('active');
        generateSlideMarkup(nextObj);
    }
}
$('#g_info').on('click',function(){
    $(this).toggleClass('active');
    $('#gallery_slide_info').toggle();
});
$('.gallery-item-js').on('click',function(){
    $('.gallery-item-js').removeClass('active');
    $(this).addClass('active');
    generateSlideMarkup(this);
});

function generateSlideMarkup(obj){
    var dataSrc = $(obj).data('src-url');
    var dataPhAuthor = $(obj).data('ph-author') ? '<h4>' + $(obj).data('ph-author') + '</h4>' : '' ;
    var dataPhTitle = $(obj).data('ph-title') ? '<p>' + $(obj).data('ph-title') + '</p>' : '';
    var dataPhDescription = $(obj).data('ph-description') ? '<p>' + $(obj).data('ph-description') + '</p>' : '';
    var dataLikes = $(obj).data('likes');
    $('#gallery_popup_slider').fadeIn();
    $('#gallery_slide').html("<img src='"+dataSrc+"'" + "alt='slide' >");
    $('#gallery_slide_info').html(dataPhAuthor+dataPhTitle+'<p><i class="fa fa-heart" aria-hidden="true"></i><span class="total-virtual-likes">'+dataLikes+'</span></p>'+dataPhDescription);

}





/**
 * *****************************************************************************************************************
 */