import * as $ from 'jquery';
import './PassiveListeners';
import './owlSliders';


import {bootFAQComponent} from "./common";
import {bootTrigger} from "./common";
import {bootToTopButton} from "./common";
import {bootButtonScrollDown} from "./common";
import {bootAnimateNumbers} from "./common";
import {bootServiceWidget} from "./common";
import {bootLoadMoreWork} from "./common";
import {bootVideoPlay} from "./common";
import {bootMaskPhoneInput} from "./common";
import bootCookie from './cookies';
import hooks from './hooks';
import './LoadMore';
import './ShowComments';
import './GallerySlider';
import './Raiting';
import './Like';
import './Calculator';
import './ScrollEffects';
import './FormHandlers';
import {bootTOCComponent} from "@/js/table-of-content";


bootFAQComponent();
bootTrigger();
bootToTopButton();
bootButtonScrollDown();
bootCookie();
bootAnimateNumbers();
bootServiceWidget();
bootLoadMoreWork();
bootVideoPlay();
bootMaskPhoneInput();
bootTOCComponent();


$.fn.extend({
    toggleText: function(stateOne, stateTwo) {
        return this.each(function() {
            stateTwo = stateTwo || '';
            $(this).text() !== stateTwo && stateOne ? $(this).text(stateTwo)
                : $(this).text(stateOne);
        });
    }
});


bootMaskPhoneInput('cu_phone');
bootMaskPhoneInput('cm_phone');
bootMaskPhoneInput('hero_cta_phone');
bootMaskPhoneInput('calculator_order_client_phone');


$(document).ready(function(){
    "use strict";

});

