import $ from "jquery";

/**
 * FAQ Component
 */

export const bootFAQComponent = () => {
	$('.js-faq-question').on('click',function(){
		const $faqItem = $('.js-faq-block');
		const $answer = $('.js-faq-answer');
		if( $(this).parent().hasClass('active') ){
			$faqItem.removeClass('active');
			$(this).parent().find('.js-faq-answer').slideUp(300);
		}else{
			$faqItem.removeClass('active');
			$(this).parent().addClass('active');
			$answer.slideUp(300);
			$(this).parent().find('.js-faq-answer').slideDown(300);
		}
	});
}

export const bootTrigger = () => {
	/*Menu*/
	$(".trigger").on('click',function () {
		$('.main-menu').slideToggle();
		$(this).toggleClass("opened");
	});
}

export const bootToTopButton = () => {
	/*Scroll top*/
	$(window).scroll(function(){
		if ($(this).scrollTop() > 100) {
			$(".to-top").addClass('active');
		}
		else {
			$(".to-top").removeClass('active');
		}
	});
	/*Scroll top Click*/
	$(".to-top").on("click", function () {
		$('html, body').animate({scrollTop: 0}, 1000);
	});
}

export const bootButtonScrollDown = () =>{
	/**
	 * ----------- Scroll down to services list ------------------
	 */
	$(".btn-scroll-down-js").on('click',function(e) {
		const t = $(".services-list h2").offset();
		$("html, body").animate({
			scrollTop: t.top
		}, 500)
	});

	/**
	 * ----------- Scroll down to Who we are section ----------------
	 */
	$(".hero-btn-scroll-down-js").on('click',function(e) {
		var t = $(".who-we-are h2").offset();
		console.log(t);
		$("html, body").animate({
			scrollTop: t.top - 100
		}, 500)
	});
}

export const bootAnimateNumbers = ()=>{
	function a(t, e) {
		var o = $(".animate-number-js");
		setTimeout(function() {
			$(o[t]).text(e)
		}, 100 + 20 * e)
	}
	$(".animate-number-js").length && $(window).scroll(function() {
		var t = $(".animate-number-js"),
			e = $(".animate-number").offset().top;
		if ($(window).scrollTop() + $(window).height() > e + 100) {
			for (var o = 0; o < t.length; o++)
				for (var n = parseInt($(t[o]).data("rating")), i = 0; i < n + 1; i++)
					a(o, i);
			$(t).removeClass("animate-number-js")
		}
	});
}

export const bootServiceWidget = ()=>{

	/**
	 * ----------- Services page Tabs ----------
	 */
	if( $('.skills-widget-wrapper').length ) {
		$('.skill-item-js').on('click',function(){
			const dataNav = $(this).data('nav');
			$('.skill-item-js').removeClass('active');
			$(this).addClass('active');
			$('.tab-content-item-js').removeClass('active');
			$('[data-tab-content='+dataNav+']').addClass('active');
		});
	}
}

export const bootLoadMoreWork = ()=>{
	$('.load-more-work-js').on('click',function(){
		var data = $(this).data('load');
		console.log(data);
		var targetBlock = $(this).parent();
		targetBlock.append(data);
		$(this).hide();
	});
}

export const bootVideoPlay = ()=>{
	$('#play').on('click',function(){
		$(this).hide();
		const id = $(this).parent().data('video');
		$(this).parent().html('<iframe width="420" height="315" src="https://www.youtube.com/embed/'+id+'?autoplay=1&mute=1&loop=1" frameborder="0" allowfullscreen="1"></iframe>')
		//.find('video').show().trigger('play');
	});

	$('.video-item-js').on('click',function(){
		const videoId = $(this).data('video');
		const $that = $(this);
		$that.find('img').hide();
		$that.find('i').hide();
		$that.html('<iframe width="320" height="250" src="https://www.youtube.com/embed/'+videoId+'?autoplay=1&mute=1&loop=1" frameborder="0" allowfullscreen="1"></iframe>');
	});
}

export const bootMaskPhoneInput = (id)=>{
	// Contact us contact form + mask
	if( document.getElementById(id) ){
		document.getElementById(id).addEventListener('input', function (e) {
			var x = e.target.value.replace(/\D/g, '').match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})/);
			e.target.value = !x[2] ? x[1] : '+' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
		});
	}
}