import * as $ from 'jquery';
import {$body, $siteHtml} from "./GlobalVars";


/**
 * *************************************************
 * ================ FORMS ==========================
 * *************************************************
 */

function testEmailPhone(field,type){
    if( type === 'email' || type === 'phone'){
        const emailPattern=/[0-9a-z_]+@[0-9a-z_-]+\.[a-z]{2,5}/i;
        const phonePattern = /^\+?([0-9]{2})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{3})[-. ]?([0-9]{0,6})$/;
        return eval(type+'Pattern').test(field);
    }
    return false;

}



// hero form
$('#hero_cta_form').on('submit',function(e){
    e.preventDefault();
    var hcNonce = $(this).find("input[name='hero_nonce']").val();
    var hcName = $('#hero_cta_name').val();
    var hcEmail = $('#hero_cta_email').val();
    var hcService = $('#hero_cta_service').val();
    var hcMsg = $('#hero_cta_msg').val();
    var check_mail =testEmailPhone(hcEmail,'email');
    var hcPhone = $('#hero_cta_phone').val();
    var check_phone =testEmailPhone(hcPhone,'phone');

    if(hcName === ''){
        $("#hero_cta_name").css({'border':'1px solid red'});
        $("#hero_cta_name_error").text('Введіть Ваше ім\'я');
    }else{
        $("#hero_cta_name").css({'border':'1px solid #7194be'});
        $("#hero_cta_name_error").text('');
    }
    if( hcEmail === '' ){
        $("#hero_cta_email").css({'border':'1px solid red'});
        $("#hero_cta_email_error").text('Введіть Ваш email');
    }else if( check_mail !== true ){
        $("#hero_cta_email").css({'border':'1px solid red'});
        $("#hero_cta_email_error").text('Не валідний email');

    }else{
        $("#hero_cta_email").css({'border':'1px solid #7194be'});
        $("#hero_cta_email_error").text('');
    }
    if( hcPhone === '' ){
        $("#hero_cta_phone").css({'border':'1px solid red'});
        $("#hero_cta_phone_error").text('Введіть Ваш телефон');
    }else if( check_phone !== true ){
        $("#hero_cta_phone").css({'border':'1px solid red'});
        $("#hero_cta_phone_error").text('Не валідний номер');

    }else{
        $("#hero_cta_phone").css({'border':'1px solid #7194be'});
        $("#hero_cta_phone_error").text('');
    }
    if( hcName !== '' && check_mail === true && check_phone === true ){
        $("#hero_cta_email").css({'border':'1px solid #7194be'});
        $("#hero_cta_email_error").text('');
        $("#hero_cta_name").css({'border':'1px solid #7194be'});
        $("#hero_cta_name_error").text('');
        $.ajax({
            type: 'POST',
            url: localizedScript.url,
            data:{
                action: 'hero_cta_form',
                nonce: hcNonce,
                user_name: hcName,
                user_email: hcEmail,
                user_phone: hcPhone,
                user_service: hcService,
                user_message: hcMsg,
                captcha: grecaptcha.getResponse(0)
            },
            success: function (result) {
                var res = JSON.parse(result);
                console.log(result);
                if(res.status === 'success'){
                    $('#hero_cta_form')[0].reset();
                    grecaptcha.reset(0);
                    $('.hero-thanks-popup').css({'display':'flex'});
                    setTimeout(function(){
                        $('.hero-thanks-popup').fadeOut();
                        $('.hero-cta-form-wrapper').fadeOut();
                        $body.removeClass('shadow');
                        $siteHtml.removeClass('shadow');
                    }, 3000);
                }
                else if(res.status === 'failed'){
                    $('.recaptcha-error-msg').html('<p>Упс... Валідація не пройшла!</p>');
                    setTimeout(function(){
                        $('.recaptcha-error-msg').html('');
                    }, 3000);
                }
            },
            error: function(jqxhr, status, exception) {
                console.log('Exception:', exception);
            }
        });
    }
});

$('.hero-cta-js').on('click',function(){
    $('.hero-cta-form-wrapper').fadeIn();
    $body.addClass('shadow');
    $siteHtml.addClass('shadow');

});
$('.hero-form-close-js').on('click',function(){
    $('.hero-cta-form-wrapper').fadeOut();
    $body.removeClass('shadow');
    $siteHtml.removeClass('shadow');

});
$('.hero-cta-form-wrapper').on('click',function(event){
    if (!$(event.target).closest("form").length) {
        $(".hero-cta-form-wrapper").fadeOut();
        $body.removeClass('shadow');
        $siteHtml.removeClass('shadow');
    }
});

// Home contact form
$('#hc_form').on('submit',function(e){
    e.preventDefault();
    var $that = $(this);
    var nonce = $that.find("input[name='hc_nonce']").val();
    var customerEmail = $that.find("input[name='hc_email']").val();
    var check_mail = testEmailPhone(customerEmail,'email');
    var userName = $that.find("input[name='hc_name']").val();
    if( customerEmail === '' ){
        $that.find("input[name='hc_email']").css({'border':'1px solid red'});
        $("#hc_email_error").text('Email є обов\`язковим полем');
    }
    else if( check_mail !== true ){
        $that.find("input[name='hc_email']").css({'border':'1px solid red'});
        $("#hc_email_error").text('Не валідний email');

    }else{
        $that.find("input[name='hc_email']").css({'border':'1px solid white'});
        $("#hc_email_error").text('');
    }
    if( userName === '' ){
        $that.find("input[name='hc_name']").css({'border':'1px solid red'});
        $("#hc_name_error").text('Ім`я є обов\`язковим полем ');
    }else{
        $that.find("input[name='hc_name']").css({'border':'1px solid white'});
        $("#hc_name_error").text('');
    }
    if( check_mail === true && userName !== '' ){
        $that.find("input[name='hc_email']").css({'border':'1px solid white'});
        $that.find("input[name='hc_name']").css({'border':'1px solid white'});
        $.ajax({
            type: 'POST',
            url: localizedScript.url,
            data:{
                action: 'home_contact_form',
                nonce: nonce,
                user_name: userName,
                user_email: customerEmail,
                captcha: grecaptcha.getResponse(1)
            },
            success: function (result) {
                var res = JSON.parse(result);
                console.log(result);
                if(res.status === 'success'){
                    $that[0].reset();
                    grecaptcha.reset(1);
                    $('.thank-popup-wrapper').fadeIn();
                    setTimeout(function(){
                        $('.thank-popup-wrapper').fadeOut();
                    }, 3000);

                }
                else if(res.status === 'user_already_exists'){
                    $("input[name='hc_email']").css({'border':'1px solid red'});
                    $("#hc_email_error").text('Email: '+customerEmail+' is already registered');
                    grecaptcha.reset(1);
                }
                else if(res.status === 'post_not_set'){
                    alert('Oops... Validation is not passed!');
                    $('.recaptcha-error-msg').html('<p>Упс... Валідація не пройшла!</p>');
                    setTimeout(function(){
                        $('.recaptcha-error-msg').html('');
                    }, 3000);
                }
                else if(res.status === 'failed'){
                    $('.recaptcha-error-msg').html('<p>Упс... Валідація не пройшла!</p>');
                    setTimeout(function(){
                        $('.recaptcha-error-msg').html('');
                    }, 3000);
                }
            },
            error: function(jqxhr, status, exception) {
                console.log('Exception:', exception);
            }
        });
    }
});



$('#cu_form').on('submit',function(e){
    e.preventDefault();
    var $that = $(this);
    var nonce = $that.find("input[name='cu_nonce']").val();
    var customerEmail = $that.find("input[name='cu_email']").val();
    var check_mail = testEmailPhone(customerEmail,'email');
    var userName = $that.find("input[name='cu_name']").val();
    var userCompany = $that.find("input[name='cu_company']").val();
    var userSubject = $that.find("input[name='cu_subject']").val();
    var userMessage = $that.find("textarea[name='cu_message']").val();
    var userPhone = $that.find("input[name='cu_phone']").val();
    var check_phone = testEmailPhone(userPhone,'phone')
    if( userPhone !== '' && check_phone !== true ){
        $that.find("input[name='cu_phone']").css({'border':'1px solid red'});
        $("#cu_phone_error").text('Не валідний телефон');
        setTimeout(function(){$that.find("input[name='cu_phone']")}, 3000);
    }
    else if (userPhone === ''){
        $that.find("input[name='cu_phone']").css({'border':'1px solid red'});
    }
    else{
        $that.find("input[name='cu_phone']").css({'border':'1px solid #F4F4F4'});
        $("#cu_phone_error").text('');
    }
    if( customerEmail === '' ){
        $that.find("input[name='cu_email']").css({'border':'1px solid red'});
        setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
        $('#cu_email_error').text('');
    }
    else if( check_mail !== true ){
        $that.find("input[name='cu_email']").css({'border':'1px solid red'});
        setTimeout(function(){$that.find("input[name='cu_email']")}, 3000);
        $('#cu_email_error').text('Не валідний email');
    }else{
        $that.find("input[name='cu_email']").css({'border':'1px solid #F4F4F4'});
        $('#cu_email_error').text('');
    }
    if( userName === '' ){
        $that.find("input[name='cu_name']").css({'border':'1px solid red'});
        setTimeout(function(){$that.find("input[name='cu_name']")}, 3000);
    }else{
        $that.find("input[name='cu_name']").css({'border':'1px solid #F4F4F4'});
    }
    if( check_phone === true && check_mail === true && userName !== '' ){
        $that.find("input[name='cu_email']").css({'border':'1px solid #F4F4F4'});
        $that.find("input[name='cu_name']").css({'border':'1px solid #F4F4F4'});
        $that.find("input[name='cu_phone']").css({'border':'1px solid #F4F4F4'});
        $.ajax({
            type: 'POST',
            url: localizedScript.url,
            data:{
                action: 'contact_us_form',
                nonce: nonce,
                user_name: userName,
                user_email: customerEmail,
                user_phone: userPhone,
                user_company: userCompany,
                user_subject: userSubject,
                user_message: userMessage,
                captcha: grecaptcha.getResponse()
            },
            success: function (result) {
                console.log(result);
                var res = JSON.parse(result);
                if(res.status === 'success'){
                    $that[0].reset();
                    grecaptcha.reset();
                    $('.thank-popup-wrapper').fadeIn();
                    setTimeout(function(){$('.thank-popup-wrapper').fadeOut()}, 3000);
                }
                else if(res.status === 'failed'){
                    grecaptcha.reset();
                    $('.recaptcha-error-msg').html('<p style="color:red;">Oops... Recaptcha is not passed!</p>');
                    setTimeout(function(){
                        $('.recaptcha-error-msg').html('');
                    }, 3000);
                }
                else if(res.status === 'post_or_nonce_not_set'){
                    grecaptcha.reset();
                    $('.recaptcha-error-msg').html('<p style="color:red;">Oops... Validation is not passed!</p>');
                    setTimeout(function(){
                        $('.recaptcha-error-msg').html('');
                    }, 3000);
                }
            },
            error: function(jqxhr, status, exception) {
                console.log('Exception:', exception);
            }
        });
    }
});

$('.order-btn-js').on('click',function(){
    var propositionId = $(this).data('order');
    $body.addClass('shadow');
    $siteHtml.addClass('shadow');
    $('.footer-order-popup-js').css({'display':'flex'});
    $('#ff_order').val(propositionId);
});
$('.footer-order-form-close-js').on('click',function(){
    $('.footer-order-popup-js').fadeOut();
    $body.removeClass('shadow');
    $siteHtml.removeClass('shadow');
});
$('.footer-order-popup-js').on('click',function(event){
    if (!$(event.target).closest("form").length) {
        $(".footer-order-popup-js").fadeOut();
        $body.removeClass('shadow');
        $siteHtml.removeClass('shadow');
    }
});

// ---------------------------------------------------- Order Form -------------------------------------------------
$('#order_form').on('submit',function(e){
    e.preventDefault();
    var recaptchaArg = 2;
    if( $('.single-post-block').length ){
        recaptchaArg = 0;
    }

    var $that = $(this);
    var nonce = $that.find("input[name='order_nonce']").val();
    var orderId = $that.find("input[name='ff_order']").val();
    var orderClientEmail = $that.find("input[name='ff_email']").val();
    var check_mail = testEmailPhone(orderClientEmail,'email');
    if( orderClientEmail === '' ){
        $that.find("input[name='ff_email']").css({'border':'1px solid red'});
        $("#ff_email_error").text('Email є обов\`язковим полем');
    }
    else if( check_mail !== true ){
        $that.find("input[name='ff_email']").css({'border':'1px solid red'});
        $("#ff_email_error").text('Не валідний email');
    }else{
        $that.find("input[name='ff_email']").css({'border':'1px solid #ccc'});
        $("#ff_email_error").text('');
    }
    if( check_mail === true && orderId !== '' ){
        $that.find("input[name='ff_email']").css({'border':'1px solid #ccc'});
        $.ajax({
            type: 'POST',
            url: localizedScript.url,
            data:{
                action: 'footer_order_form',
                nonce: nonce,
                order_id: orderId,
                user_email: orderClientEmail,
                captcha: grecaptcha.getResponse(recaptchaArg)
            },
            success: function (result) {
                var res = JSON.parse(result);
                console.log(result);
                if(res.status === 'success'){
                    $that[0].reset();
                    grecaptcha.reset(recaptchaArg);
                    $('.thank-popup-wrapper').fadeIn();
                    setTimeout(function(){
                        $('.footer-order-popup').fadeOut();
                        $('.thank-popup-wrapper').fadeOut();
                        $body.removeClass('shadow');
                        $siteHtml.removeClass('shadow');
                    }, 3000);
                }
                else if(res.status === 'failed'){
                    $('#ff_email_error').text('Oops... Something went wrong!');
                    setTimeout(function(){
                        $('#ff_email_error').text('');
                    }, 3000);
                }
            },
            error: function(jqxhr, status, exception) {
                console.log('Exception:', exception);
            }
        });
    }
});

$('.cta-call-me-js').on('click',function(){
    $body.addClass('shadow');
    $siteHtml.addClass('shadow');
    $('.call-me-popup-js').css({'display':'flex'});
});
$('.call-me-popup-close-js').on('click',function(event){
    $('.call-me-popup-js').fadeOut();
    $body.removeClass('shadow');
    $siteHtml.removeClass('shadow');
});
$('.call-me-popup-js').on('click',function(event){
    if (!$(event.target).closest("form").length) {
        $(".call-me-popup-js").fadeOut();
        $body.removeClass('shadow');
        $siteHtml.removeClass('shadow');
    }
});

// -------------------------------------------- Call me Form -------------------------------------------------------
$('#call_me_form').on('submit',function(e){
    e.preventDefault();
    var recaptchaArg = 0;
    if( $('.home').length ){
        recaptchaArg = 3;
    }
    if( $('.single-proposition').length || $('.page-template-page-contact-us').length || $('.page-template-page-calculator').length || $('.page-template-page-services').length ){
        recaptchaArg = 1;
    }
    console.log(recaptchaArg + '' + grecaptcha.getResponse(recaptchaArg));
    var $that = $(this);
    var nonce = $that.find("input[name='call_me_nonce']").val();
    var userPhone = $that.find("input[name='cm_phone']").val();
    var check_phone = testEmailPhone(userPhone,'phone');

    if( check_phone !== true ){
        $that.find("input[name='cm_phone']").css({'border':'1px solid red'});
        $("#cmf_phone_error").text('Не коректний номер');
    }else{
        $that.find("input[name='cm_phone']").css({'border':'1px solid #ccc'});
        $("#cmf_phone_error").text('');
    }
    if( check_phone === true ){
        $that.find("input[name='cm_phone']").css({'border':'1px solid #ccc'});
        $.ajax({
            type: 'POST',
            url: localizedScript.url,
            data:{
                action: 'call_me_form',
                nonce: nonce,
                user_phone: userPhone,
                captcha: grecaptcha.getResponse(recaptchaArg)
            },
            success: function (result) {
                var res = JSON.parse(result);
                console.log(result);
                if(res.status === 'success'){
                    $that[0].reset();
                    grecaptcha.reset(recaptchaArg);
                    $('.thank-popup-wrapper').fadeIn();
                    setTimeout(function(){
                        $('.call-me-popup-js').fadeOut();
                        $('.thank-popup-wrapper').fadeOut();
                        $body.removeClass('shadow');
                        $siteHtml.removeClass('shadow');
                    }, 3000);
                }
                else if(res.status === 'failed'){
                    $('#cmf_phone_error').text('Упс... Щось пішло не так!');
                    setTimeout(function(){
                        $('#cmf_phone_error').text('');
                    }, 3000);
                }
            },
            error: function(jqxhr, status, exception) {
                console.log('Exception:', exception);
            }
        });
    }
});