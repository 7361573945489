import $ from "jquery";

const hasUserSeenCookies = () => {
	return localStorage.getItem('has-user-seen-cookies') === 'yes';
};

const markUserSeenCookie = () => {
	localStorage.setItem('has-user-seen-cookies', 'yes');
};

export default async function bootCookies() {
	const $cookies = $('#warning_popup');
	const $cookiesAccept = $('#warning_popup_close');
	const classIsHidden = 'hidden';

	if (!hasUserSeenCookies()) {
		setTimeout(function () {
			$cookies.fadeIn(200).fadeTo(400, 1);
		}, 1000);

		$cookiesAccept.click(function () {
			$cookies.fadeOut(200).fadeTo(400, 1);

			markUserSeenCookie();
		});
	} else {
		$cookies.hide();
		$cookies.addClass(classIsHidden);
	}
}
