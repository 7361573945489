import * as $ from 'jquery';
import bootReadCookie from "./ReadCookies";
import bootSetCookie from "./SetCookies";

// Rating
async function hasVoted(dataId){
    const userVoted = await bootReadCookie('user_voted_'+ dataId);
    if( userVoted ){
        return true;
    }
    return false;
}


const $jsRating = $(".js-rating");

$jsRating.hover(
    async function(e) {
    },async function(e){
        const dataId = $(this).data('id');
        const hasUserVoted = await hasVoted(dataId);
        if( !hasUserVoted ) {
            const innerDefaultDataWidth = $(this).data('default-value');
            const defaultWidth = parseInt(innerDefaultDataWidth);
            $(this).find('.js-rating_inner').css("width", defaultWidth + "%");
        }
    }
);
$jsRating.mousemove(async function(e){
    const dataId = $(this).data('id');
    const hasUserVoted = await hasVoted(dataId);
    if( !hasUserVoted ){
        var actionWidth = e.offsetX;
        if(actionWidth > 100){
            actionWidth = 100;
        }
        $(this).find('.js-rating_inner').css("width", actionWidth + "%");
    }
});
$jsRating.on('click',async function(e) {
    const dataId = $(this).data('id');
    const hasUserVoted = await hasVoted(dataId);
    if( !hasUserVoted ){
        const $that = $(this);
        const dataVotes = $that.data('votes');
        const updatedVotes = dataVotes+1;
        const innerWidth = $that.find('.js-rating_inner').width();
        const perc = e.offsetX;
        $that.find('.js-rating_inner').css("width", perc + "%");
        $.ajax({
            type: 'POST',
            url: votesScript.url,
            data:{
                action: votesScript.action,
                nonce: votesScript._wp_nonce,
                data_id: dataId,
                rating: innerWidth,
                votes: updatedVotes
            },
            success: function (result) {
                const res = JSON.parse(result);
                if( res.status === 'success' ){
                    $('.js-votes').text(updatedVotes);
                    $jsRating.data('votes',updatedVotes);
                    $jsRating.data('default-value',innerWidth);
                    bootSetCookie('user_voted_'+ dataId,'voted',5);
                }else{
                    console.log(res.status);
                }
            }
        });
    }
});